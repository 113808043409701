/**
 * This method returns the person type for A/B testing
 *
 */
export const getPersonType = (page_type) => {
  return localStorage.getItem(page_type);
};

/**
 * This method sets the person type for A/B testing
 * @param {*} personType
 */
export const setPersonType = (personType: any, page_type, force = false) => {
  if (!getPersonType(page_type) || force) {
    localStorage.setItem(page_type, personType);
  }
};
/**
 * Returns a random person type. To start with it returns either A or B
 */
export const getRandomPersonType = () => {
  return Math.floor(Math.random() * 10) % 2 === 0 ? "A" : "B";
};

/**
 * Sets persontype as random
 * @param {*} force
 */
export const setRandomPersonType = (page_type) => {
  setPersonType(getRandomPersonType(), page_type);
};
