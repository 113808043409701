/* eslint-disable react/require-default-props */
import Image, { StaticImageData } from "next/image";
import { CSSProperties } from "react";

type PropsType = {
  src: string | StaticImageData;
  alt: string;
  height?: number;
  width?: number | `${number}`;
  className?: string | null;
  loading?: "lazy" | "eager" | undefined;
  quality?: number;
  placeholder?: "blur" | "empty" | undefined;
  layout?: boolean;
  priority?: boolean;
  styles?: CSSProperties;
  responsive?: boolean;
  onClick?: any;
  unoptimized?: boolean;
};

const TImage = (props: PropsType) => {
  const {
    src,
    width,
    height,
    alt,
    className,
    loading = "lazy",
    quality,
    placeholder,
    styles = {},
    priority = false,
    responsive = false,
    onClick,
    unoptimized = false,
  } = props;

  // const contentfulLoader = (): string => {
  //   if (width) {
  //     const params = [`w=${width}`, `q=${quality || 80}`];
  //     return `${src}?${params.join("&")}`;
  //   }
  //   return src;
  // };

  return width ? (
    <Image
      src={src}
      alt={alt}
      width={width || 20}
      height={height || 20}
      // loader={contentfulLoader}
      className={className || ""}
      loading={loading}
      placeholder={placeholder || "empty"}
      style={{ height: "auto", ...styles }}
      priority={priority}
      fill={responsive}
      onClick={onClick}
      unoptimized={unoptimized}
    />
  ) : (
    <Image
      src={src}
      alt={alt}
      // loader={contentfulLoader}
      className={className || ""}
      loading={loading}
      placeholder={placeholder || "empty"}
      style={styles}
      priority={priority}
      fill={responsive}
      onClick={onClick}
    />
  );
};

export default TImage;
