import { useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Typography } from "antd";
import { isMobile } from "mobile-device-detect";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { APIStateKeysEnum, useProfile } from "services/ApiHooks";
import { Routes } from "services/constants/RouteConstants";
import { updateMilestone } from "services/dashboard-services";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import { getAuthToken } from "utils/authentication";
import TImage from "../TImage";
import styles from "./FeatureNudge.module.scss";

const { Paragraph } = Typography;
const FeatureNudge = () => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { entity_id } = router.query;
  const isLoggedIn = getAuthToken() ? true : false;
  const { user: profile } = useProfile(isLoggedIn);
  const queryClient = useQueryClient();
  const [nudgeData, setNudgeData] = useState<any>();
  const closeMilestone = async () => {
    Analytics.track("Profile Nudge Closed", {
      position: "Popup",
      type: nudgeData?.nudge_type,
      cta: nudgeData?.cta_text || "Add to socials",
      title: nudgeData?.title || "NA",
      user_id: profile?.id,
      username: profile?.username,
    });
    try {
      await updateMilestone(
        nudgeData?.id,
        "update_seen",
        nudgeData?.nudge_type
      );
      queryClient.refetchQueries([APIStateKeysEnum.Profile]);
      setOpen(false);
    } catch {
      setOpen(false);
    }
  };

  const checkRoute = (): any => {
    var match: any = null;
    match = Routes.find((route) => route.pattern === location?.pathname);
    if (!match) {
      match = Routes.find((route) => route.pattern === router?.pathname);
    }
    if (!match) {
      match = { name: "NA", type: "public" };
    }
    return match?.type === "private" ? true : false;
  };

  const shareMilestone = () => {
    Analytics.track("Profile Nudge Clicked", {
      position: "Popup",
      type: nudgeData?.nudge_type,
      cta: nudgeData?.cta_text || "Add to socials",
      title: nudgeData?.title || "NA",
      user_id: profile?.id,
      username: profile?.username,
    });
    closeMilestone();
    router.push(
      `/dashboard/create-post/${nudgeData?.redirect_url}?source=nudge`
    );
  };

  useEffect(() => {
    if (
      isLoggedIn &&
      checkRoute() &&
      !isMobile &&
      profile?.user_nudge &&
      profile?.user_nudge?.nudge_type === "sharing" &&
      !entity_id
    ) {
      setNudgeData(profile?.user_nudge);
      setOpen(true);
      Analytics.track("Popup Viewed", {
        type: profile?.user_nudge?.nudge_type || "NA",
        Screen: "Home",
        Origin: "Automatic",
        cta: profile?.user_nudge?.cta_text || "Add to socials",
        title: profile?.user_nudge?.title || "NA",
        user_id: profile?.id,
        username: profile?.username,
      });
    }
  }, [isLoggedIn, profile?.user_nudge]);

  return (
    <>
      {open && (
        <>
          <Modal
            onCancel={closeMilestone}
            width={360}
            styles={{ content: { padding: 4, overflow: "hidden" } }}
            className={styles.SharingNudge}
            open={open}
            footer={null}
            closable={false}
          >
            <Paragraph className={styles?.SM_Image_Box}>
              <IoMdClose
                className={styles?.SM_Close}
                color="#000"
                onClick={closeMilestone}
              />
              <TImage
                src={
                  nudgeData?.image_url || "/images/dashboard/product-nudge.svg"
                }
                unoptimized
                alt="Desktop Nudge"
                width={100}
                className={styles?.SN_Image}
              />
            </Paragraph>
            <Paragraph className={styles?.SM_Info_Box}>
              <Paragraph className={styles?.SM_Title}>
                {nudgeData?.title || "New memory"}
              </Paragraph>
              <Paragraph className={styles?.SM_Desc}>
                {nudgeData?.sub_title || "Save this memory to your topmate"}
              </Paragraph>
              <Button
                onClick={(): void => {
                  shareMilestone();
                }}
                key="cancel"
                className={styles?.SM_Button}
                block
              >
                {nudgeData?.cta_text || "Add to socials"}
              </Button>
            </Paragraph>
          </Modal>
          {/* {nudgeData?.nudge_type === "sharing" ? (
            <Modal
              onCancel={closeMilestone}
              width={360}
              open={open}
              centered
              className="modal-container"
              footer={null}
            >
              <Paragraph className="modal-info">
                <TImage
                  src={
                    nudgeData?.image_url ||
                    "/images/dashboard/product-nudge.svg"
                  }
                  alt="Desktop Nudge"
                  width={470}
                />
              </Paragraph>
              <Paragraph className={styles?.Info_Box}>
                <Paragraph className={styles?.FN_title}>
                  {nudgeData?.title || "Add to socials"}
                </Paragraph>
                <Paragraph className={styles?.FN_desc}>
                  {nudgeData?.sub_title ||
                    " Congrats on getting your first booking! Now add your topmate to your socials to keep new bookings coming!"}
                </Paragraph>
                <Button
                  onClick={(): void => {
                    nudgeData?.redirect_url &&
                      router.push(nudgeData?.redirect_url);
                    closeMilestone();
                  }}
                  key="cancel"
                  className="btn-dark"
                  block
                >
                  {nudgeData?.cta_text || "Add to socials"}
                </Button>
              </Paragraph>
            </Modal>
          ) : (
            <Modal
              onCancel={closeMilestone}
              width={360}
              open={open}
              centered
              className="modal-container"
              footer={null}
            >
              <Paragraph className="modal-info">
                <TImage
                  src={
                    nudgeData?.image_url ||
                    "/images/dashboard/product-nudge.svg"
                  }
                  alt="Desktop Nudge"
                  width={470}
                />
              </Paragraph>
              <Paragraph className={styles?.Info_Box}>
                <Paragraph className={styles?.FN_title}>
                  {nudgeData?.title || "Add to socials"}
                </Paragraph>
                <Paragraph className={styles?.FN_desc}>
                  {nudgeData?.sub_title ||
                    " Congrats on getting your first booking! Now add your topmate to your socials to keep new bookings coming!"}
                </Paragraph>
                <Button
                  onClick={(): void => {
                    nudgeData?.redirect_url &&
                      router.push(nudgeData?.redirect_url);
                    closeMilestone();
                  }}
                  key="cancel"
                  className="btn-dark"
                  block
                >
                  {nudgeData?.cta_text || "Add to socials"}
                </Button>
              </Paragraph>
            </Modal>
          )} */}
        </>
      )}
    </>
  );
};

export default FeatureNudge;
