import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { getCurrentRouteConstant } from "services/constants/RouteConstants";
import config from "utils/config";
import { getCookie, removeCookie, setCookie } from "utils/cookie-manager";

interface UtmObject {
  utm_source?: string;
  utm_page?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_user?: string;
  http_referer?: string;
}

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const getDefaultTimezone = (): string => {
  let defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (defaultTimezone === "Asia/Calcutta" || !defaultTimezone)
    defaultTimezone = "Asia/Kolkata";
  return defaultTimezone;
};

export const removeHttp = (url: string): string => {
  return url.replace(/^https?:\/\//, "");
};

export const saveTimezone = (timezone): void => {
  setCookie("timezone", timezone);
};

export const removeTimeZone = (): void => {
  removeCookie("timezone", { path: "/" });
};

export const getTimezone = (): string => {
  let timezone = getCookie("timezone");
  if (!timezone) timezone = getDefaultTimezone();
  saveTimezone(timezone);
  return timezone;
};

export const getWeekCount = (date) => {
  let monthStart = new Date(date);
  monthStart.setDate(0);
  let offset = ((monthStart.getDay() + 1) % 7) - 1; // -1 is for a week starting on Monday
  return Math.ceil((date.getDate() + offset) / 7);
};

export const getTimezoneDate = (date = new Date()): any => {
  return dayjs(date).tz(getTimezone());
};

export const getReadableDate = (datetime, duration): string => {
  let day = "";
  let locale = navigator.language;
  let timezone = getTimezone();
  let shortTimezone: any = "";
  if (locale) {
    shortTimezone = new Intl.DateTimeFormat(dayjs.locale(), {
      timeZone: timezone,
      timeZoneName: "short",
    })
      .format(Date.now())
      .split(" ")[1];
  }
  if (
    getTimezoneDate(datetime).date() === getTimezoneDate().date() &&
    getTimezoneDate(datetime).month() === getTimezoneDate().month()
  )
    day = "Today";
  else day = getTimezoneDate(datetime).format("ddd, DD MMM");

  return (
    day +
    " . " +
    getTimezoneDate(datetime).format(" hh:mm") +
    " - " +
    getTimezoneDate(datetime).add(duration, "minutes").format(` hh:mm A`) +
    ` (${shortTimezone})`
  );
};

export const getReadableTime = (datetime, duration): string => {
  let day = "";
  let locale = navigator.language;
  let timezone = getTimezone();
  let shortTimezone: any = "";
  if (locale) {
    shortTimezone = new Intl.DateTimeFormat(dayjs.locale(), {
      timeZone: timezone,
      timeZoneName: "short",
    })
      .format(Date.now())
      .split(" ")[1];
  }
  if (
    getTimezoneDate(datetime).date() === getTimezoneDate().date() &&
    getTimezoneDate(datetime).month() === getTimezoneDate().month()
  )
    day = "Today";
  else day = getTimezoneDate(datetime).format("ddd, DD MMM");

  return (
    getTimezoneDate(datetime).format(" hh:mm") +
    " - " +
    getTimezoneDate(datetime).add(duration, "minutes").format(` hh:mm A`) +
    ` (${shortTimezone})`
  );
};

export const getReadableDay = (datetime): string => {
  let day = "";
  let locale = navigator.language;
  let timezone = getTimezone();
  let shortTimezone: any = "";
  if (locale) {
    shortTimezone = new Intl.DateTimeFormat(dayjs.locale(), {
      timeZone: timezone,
      timeZoneName: "short",
    })
      .format(Date.now())
      .split(" ")[1];
  }
  if (
    getTimezoneDate(datetime).date() === getTimezoneDate().date() &&
    getTimezoneDate(datetime).month() === getTimezoneDate().month()
  )
    day = "Today";
  else day = getTimezoneDate(datetime).format("ddd, DD MMM");
  return day;
};

export const getReadableDateOnly = (datetime): string => {
  let day = "";
  let locale = navigator.language;
  let timezone = getTimezone();
  let shortTimezone: any = "";
  if (locale) {
    shortTimezone = new Intl.DateTimeFormat(dayjs.locale(), {
      timeZone: timezone,
      timeZoneName: "short",
    })
      .format(Date.now())
      .split(" ")[1];
  }
  if (
    getTimezoneDate(datetime).date() === getTimezoneDate().date() &&
    getTimezoneDate(datetime).month() === getTimezoneDate().month()
  )
    day = "Today";
  else day = getTimezoneDate(datetime).format("DD MMM");
  return day;
};

/**
 * Formats the number to following format "4,000"
 * @param {*} number or string
 * @returns
 */
export const formatNumber = (number, flexible = false): number | string => {
  return (
    Number(parseFloat(number).toFixed(1)).toLocaleString() +
    (flexible ? "+" : "")
  );
};

export const CurrencyFormatter = (
  value: number,
  decimals = 0,
  currency = "INR"
): string => {
  const format = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
    minimumFractionDigits: decimals,
  });
  return format.format(value);
};

/**
 *
 * @param {*} url
 * @returns The url with all of the query params removed
 */
export const removeAllQueryParams = (url) => {
  const indexOfQuesitonMark = url.indexOf("?");
  if (indexOfQuesitonMark === -1) return url;
  return url.slice(0, indexOfQuesitonMark);
};

export const getFileType = (fileType) => {
  const parts = fileType ? fileType.split(".") : [];
  const exe = parts?.length > 0 ? parts[parts.length - 1] : null;
  try {
    return exe;
  } catch (e) {
    return undefined;
  }
};

export const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = (): void => {
      resolve(true);
    };
    script.onerror = (): void => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

export const createTimeList = (diff, start_at, prepend) => {
  let hours, minutes, ampm, thf;
  const createTimeList: any = [];
  let oneTimeObject = {};

  if (diff === undefined || isNaN(diff)) {
    diff = 30;
  }
  let i_start = 0;
  if (start_at && start_at < 24) {
    i_start = start_at * 60;
  }
  const i_end = 1430 + i_start;

  for (let i = i_start; i <= i_end; i += diff) {
    hours = Math.floor(i / 60);
    thf = hours >= 24 ? hours - 24 : hours;
    minutes = i % 60;
    if (minutes < 10) {
      minutes = "0" + minutes; // adding leading zero
    }
    ampm = hours % 24 < 12 ? "AM" : "PM";
    hours = hours % 12;
    if (hours === 0) {
      hours = 12;
    }
    hours = hours < 10 ? hours : hours;
    thf = thf < 10 ? thf : thf;

    oneTimeObject = {
      key: (prepend ? prepend : "") + thf + ":" + minutes,
      value: thf + ":" + minutes,
      compare: thf * minutes,
      label: hours + ":" + minutes + " " + ampm,
    };
    createTimeList.push(oneTimeObject);
  }
  return createTimeList;
};

// Required for downloading image files
export const toDataURL = (url): any => {
  return fetch(url)
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      return URL.createObjectURL(blob);
    });
};

export const downloadMedia = async (fileURL, fileName = ""): Promise<void> => {
  const a = document.createElement("a");
  a.href = await toDataURL(fileURL);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const getAuthorisedLinkedinRedirectUrl = (): string => {
  return `${config.BASE_URL}authorise`;
};

const getUnauthorisedLinkedinRedirectUrl = (): string => {
  return `${config.BASE_URL}authorise-consumer`;
};

const storeConsumerEmail = (email: string) => {
  localStorage.setItem("consumer_email", email);
};

export const linkedOautRedirectUrl = (
  authorised: boolean,
  email: string
): string => {
  if (!authorised) storeConsumerEmail(email);
  const redirectUrl = authorised
    ? getAuthorisedLinkedinRedirectUrl()
    : getUnauthorisedLinkedinRedirectUrl();
  return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${config.LINKEDIN_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=w_member_social%20r_liteprofile%20r_emailaddress`;
};

export const TwitterOautRedirectUrl = (
  authorised: boolean,
  email: string
): string => {
  if (!authorised) storeConsumerEmail(email);
  // const redirectUrl = authorised
  //   ? getAuthorisedLinkedinRedirectUrl()
  //   : getUnauthorisedLinkedinRedirectUrl();
  const redirectUrl = `${config.BASE_URL}auth/twitter`;

  return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${config.TWITTER_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=tweet.read%20tweet.write%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`;
};

export const signInWithLinkedIn = (authorised = true, email = ""): void => {
  window.open(linkedOautRedirectUrl(authorised, email), "_blank");
};

export const signInWithTwitter = (authorised = true, email = ""): void => {
  window.open(TwitterOautRedirectUrl(authorised, email), "_blank");
};

export const customFetcher = async (url: string): Promise<any> => {
  const response = await fetch(
    `https://linkedin-post-preview.herokuapp.com/v2?url=${url}`
  );
  const json = await response.json();
  return json?.metadata;
};

export const getClickableLink = (link) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `//${link}`;
};

export const getLinkWithPretag = (link) => {
  return link.startsWith("http://") || link.startsWith("https://")
    ? link
    : `https://${link}`;
};

export const getMarketingSource = (router): UtmObject => {
  const utmCookies = [
    "utm_source",
    "utm_page",
    "utm_medium",
    "utm_campaign",
    "utm_user",
    "http_referer",
    "utm_content",
    "utm_term",
  ];
  const utmValues = utmCookies.map((name) => getCookie(name));
  const match: any = getCurrentRouteConstant(router);
  const utmObject: UtmObject = utmCookies.reduce((obj, name, index) => {
    const flatObj = { ...obj, [name]: utmValues[index] };
    return flatObj;
  }, {});
  if (match) {
    utmObject.utm_page = match?.name || "NA";
  }
  utmObject.http_referer = document?.referrer || "";
  return utmObject;
};

export const arraysContainSameElements = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  console.log("sorted", sortedArr1, sortedArr2);
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
};
