import "@styles/rootVars.scss";
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { SpeedInsights } from "@vercel/speed-insights/next";
import { ConfigProvider } from "antd";
import "antd/dist/reset.css";
import FeatureNudge from "components/Common/FeatureNudge/FeatureNudge";
import { ANT_CUSTOM_THEME } from "configs/AntThemeConfig";
import { AnimatePresence, MotionConfig, motion } from "framer-motion";
import mixpanel from "mixpanel-browser";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { Router, useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Routes } from "services/constants/RouteConstants";
import "styles/global.scss";
import { insertSpaces } from "utils/Analytics/Analytics";
import { Analytics } from "utils/Analytics/AnalyticsHelper";
import config from "utils/config";
import { setCookie } from "utils/cookie-manager";
import zipy from "zipyai";

const ZIPY_KEY = config.ZIPPY_KEY ?? "";

const MaintenanceMode = dynamic(
  () => import("components/Common/MaintenanceMode"),
  {
    ssr: false,
  }
);
const tagManagerArgs = {
  gtmId: config?.GTM_ID,
};

// Create a client for react query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 2 * (60 * 1000), // 2 mins
      cacheTime: 10 * (60 * 1000), // 10 mins
    },
  },
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const { query } = router;
  const isMaintenanceMode = config.MAINTENANCE_MODE === "true" ? true : false;
  const isWhitelabel = config?.IS_WHITELABEL === "true" ? true : false;

  const webURL = config.BASE_URL?.substring(0, config.BASE_URL?.length - 1);
  const canonicalUrl = (
    webURL + (router.asPath === "/" ? "" : router.asPath)
  ).split("?")[0];

  const storeUTMParameters = (): void => {
    const urlParams = new URLSearchParams(window.location.search);
    const utmCookies = [
      "utm_source",
      "utm_page",
      "utm_medium",
      "utm_campaign",
      "utm_user",
      "http_referer",
      "utm_content",
      "utm_term",
    ];
    if (query.http_referrer) {
      setCookie("http_referrer", urlParams.get("http_referrer") || "");
    }
    utmCookies.forEach((name) => {
      if (urlParams.get(name)) {
        setCookie(name, urlParams.get(name) || "");
      }
    });
  };

  const logPageLoadEvents = (): void => {
    var match: any = null;
    match = Routes.find((route) => route.pattern === location?.pathname);
    if (!match) {
      match = Routes.find((route) => route.pattern === router?.pathname);
    }
    if (!match) {
      match = { name: "NA" };
    }
    const currentPage: string = match?.name ? insertSpaces(match?.name) : "";
    ReactGA.set({ page: location?.pathname }); // Update the user's current page
    // ReactGA.pageview(window.location.pathname);
    if (match && currentPage !== "NA") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname,
        title: currentPage,
      });
      Analytics.page(currentPage);
    }
    // zipy initialization
    if (config.IS_PROD === "true") {
      if (
        [
          "Public Profile",
          "Public Homepage",
          "Pricing",
          "Sign Up",
          "Login",
          "Privacy",
          "About",
          "Video Services",
          "Webinar Services",
          "Package Services",
          "Query Services",
          "Document Services",
          "Upcoming Video Calls",
          "Upcoming Webinar Calls",
          "Upcoming Package Calls",
          "Service Page",
          "Video Call Ended",
          "Testimonial Success",
          "Rewards",
          "Onboarding About",
        ]?.includes(currentPage)
      ) {
        Math.random() < 0.1 && zipy.init(ZIPY_KEY);
      } else {
        Math.random() < 0.5 && zipy.init(ZIPY_KEY);
      }
    }
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    // Note: This is configured to track an event for every page view automatically. We also recommend
    // using localStorage instead of the default cookie for persistence.
    mixpanel.init(config.MIXPANEL_TOKEN, {
      debug: config.IS_PROD === "true" ? false : true,
      persistence: "localStorage",
    });

    setTimeout(() => {
      storeUTMParameters();
      logPageLoadEvents();
    });

    Router.events.on("routeChangeComplete", () => {
      storeUTMParameters();
      logPageLoadEvents();
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <ConfigProvider theme={ANT_CUSTOM_THEME}>
          <Head>
            <link rel="canonical" href={canonicalUrl} />
            <meta property="og:url" content={canonicalUrl} />
            <meta
              property="og:image"
              content="http://example.com/favicon.png"
            ></meta>
            <meta
              property="twitter:card"
              content="http://example.com/favicon.png"
            ></meta>
          </Head>
          {isMaintenanceMode ? (
            <MaintenanceMode />
          ) : (
            <AnimatePresence mode="wait">
              <MotionConfig transition={{ duration: 1 }}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <FeatureNudge />
                  <Component {...pageProps} key={router?.asPath} />
                  <SpeedInsights sampleRate={0.05} />
                </motion.div>
              </MotionConfig>
            </AnimatePresence>
          )}
        </ConfigProvider>
        {config.IS_PROD === "false" && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
      </Hydrate>
    </QueryClientProvider>
  );
};

export default MyApp;
