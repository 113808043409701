import axios from "axios";
import Router from "next/router";
import {
  getAuthToken,
  getFollowerAuthToken,
  removeAuthToken,
} from "utils/authentication";
import { getTimezone } from "utils/common";
import config from "utils/config";
import { getCookie } from "utils/cookie-manager";
import { mError } from "utils/logger";

const controller = new AbortController();

const axiosInstance = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Accept-Language": "en",
  },
  signal: controller.signal,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 401) {
      removeAuthToken();
      mError("Unauthorized");
      Router.push("/sign-in");
    }
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      removeAuthToken();
      mError("Access Denied");
      Router.push("/sign-in");
    }
    return Promise.reject(error);
  }
);

const getConfig = (params, extraConfigs, isUpdateCall) => {
  let config: any = {
    ...(isUpdateCall ? { body: params } : { params: params }),
  };

  const token = getAuthToken();
  const followerToken = getFollowerAuthToken();
  if (extraConfigs && extraConfigs.viaOauth) {
    config = {
      ...config,
      headers: { Authorization: `Token ${extraConfigs?.token || token}` },
    };
  }
  if (extraConfigs && extraConfigs.captcha) {
    config.headers = {
      ...config.headers,
      "X-Captcha-Token": extraConfigs?.captcha,
    };
  }
  if (extraConfigs && extraConfigs.glocalOcID) {
    config.headers = {
      ...config.headers,
      "X-OC-REQUEST-ID": extraConfigs?.glocalOcID,
    };
  }
  if (extraConfigs && extraConfigs.followerToken) {
    config.headers = {
      ...config.headers,
      "X-follower-token": followerToken,
    };
  }
  if (extraConfigs && extraConfigs.searchTrackingID) {
    config.headers = {
      ...config.headers,
      "X-tracking-id": extraConfigs?.searchTrackingID,
    };
  }
  if (extraConfigs && extraConfigs.utmData) {
    config.headers = {
      ...config.headers,
      "X-utm-params": JSON.stringify(extraConfigs?.utmData),
    };
  }
  if (extraConfigs && extraConfigs.embedIdentifier) {
    config.headers = {
      ...config.headers,
      "X-Web-UI": "embed",
    };
  }
  config = {
    ...config,
    headers: {
      ...config?.headers,
      "X-timezone": getTimezone(),
      "X-user-agent": "topmate",
      "X-DEVICE-ID": getCookie("topmate_device_id"),
      // HTTP_X_CLIENT_COUNTRY: "IN",
    },
  };
  return config;
};

const axiosRequest = (apiKey, method, dataObj, extraConfigs) => {
  const methods = method.toLowerCase();
  if (methods === "get" || methods === "delete" || methods === "head") {
    let params = {};
    params = dataObj ? dataObj : params;
    const config = getConfig(params, extraConfigs, false);
    return axiosInstance[methods](apiKey, config);
  } else if (methods === "post" || methods === "put" || methods === "patch") {
    let params = {};
    params = dataObj ? dataObj : params;
    const config = getConfig(params, extraConfigs, true);
    return axiosInstance[methods](apiKey, dataObj, config);
  } else {
    return Promise.reject("Invalid method");
  }
};

export const post = async (
  apiKey,
  params = {},
  extraConfigs = {}
): Promise<any> => {
  const response =
    apiKey && (await axiosRequest(apiKey, "post", params, extraConfigs));
  return response;
};

export const patch = async (
  apiKey,
  params = {},
  extraConfigs = {}
): Promise<any> => {
  const response =
    apiKey && (await axiosRequest(apiKey, "patch", params, extraConfigs));
  return response;
};

export const get = async (
  apiKey,
  params = {},
  extraConfigs = {}
): Promise<any> => {
  const response =
    apiKey && (await axiosRequest(apiKey, "get", params, extraConfigs));
  return response;
};

export const del = async (
  apiKey,
  params = {},
  extraConfigs = {}
): Promise<any> => {
  const response =
    apiKey && (await axiosRequest(apiKey, "delete", params, extraConfigs));
  return response;
};

export const put = async (
  apiKey,
  params = {},
  extraConfigs = {}
): Promise<any> => {
  const response =
    apiKey && (await axiosRequest(apiKey, "put", params, extraConfigs));
  return response;
};
